<template>
  <section v-if="!welcome" class="logo large-logo">
    <img
      class="large-logo-img company-img"
      v-if="companyLogoData"
      :src="companyLogoData.url"
      alt="Logo"
    />
    <img
      v-else
      class="large-logo-img clebex-img"
      src="../../assets/logo@2x.png"
      alt="Clebex logo"
      :class="{ hidden: !noCompanyLogo }"
    />
    <powered-by-clebex v-if="!noCompanyLogo" />
  </section>
  <img
    class="large-logo-img company-img"
    v-if="welcome && companyLogoData"
    :src="companyLogoData.url"
    alt="Logo"
  />
  <img
    v-else-if="welcome"
    class="large-logo-img clebex-img"
    src="../../assets/logo@2x.png"
    alt="Clebex logo"
    :class="{ hidden: !noCompanyLogo }"
  />
  <powered-by-clebex v-if="welcome && !noCompanyLogo" />
  <h1 v-if="welcome">Welcome</h1>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions, mapState } from "vuex";
import { getSubdomain } from "@/services/http-service";

export default {
  name: "large-logo",
  components: {
    PoweredByClebex: defineAsyncComponent(() =>
      import("@/components/global/PoweredByClebex")
    )
  },
  data() {
    return {
      savedLogo: null,
      noCompanyLogo: false
    };
  },
  computed: {
    ...mapState("companyProfile", ["companyLogo"]),
    companyLogoData() {
      if (this.companyLogo && this.companyLogo.data) {
        return this.companyLogo.data;
      }
      return null;
    }
  },
  created() {
    if (getSubdomain()) {
      this.getCompanyLogo();
    } else {
      this.noCompanyLogo = true;
    }
  },
  methods: {
    ...mapActions("companyProfile", ["getCompanyLogo"])
  },
  watch: {
    companyLogo: {
      handler(val) {
        if (typeof val === "string" && !val) {
          this.noCompanyLogo = true;
        }
      },
      immediate: true
    }
  },
  props: {
    welcome: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
